// @file Use this to queue operations that are wrapped in promises.
// When one promise completes, the next one is automatically triggered.

import { shouldLog } from '@@/vuexstore/plugins/logger'
import { partition } from 'lodash-es'

function devLog(message, ...args): void {
  const logThisModule = false
  if (!logThisModule || !shouldLog) return
  console.log(`PromiseQueue: ${message}`, ...args) // goodcheck-disable-line
}

interface QueueablePromise {
  key: string
  method: () => Promise<any>
  resolveNow: (value) => void
  metadata?: any
}

interface EnqueueOptions {
  metadata?: any
  firstInLine?: boolean
}

export default class PromiseQueue {
  private currentPromise: QueueablePromise | null = null
  private queue: QueueablePromise[] = []

  public get currentKey(): string | undefined {
    return this.currentPromise?.key
  }

  public get lastQueuedKey(): string | null {
    if (this.queue.length < 1) return null
    return this.queue[this.queue.length - 1].key
  }

  public get isEmpty(): boolean {
    return !this.currentPromise && this.queue.length < 1
  }

  public enqueue(key: string, method: () => Promise<any>, options: EnqueueOptions = {}): Promise<any> {
    devLog('enqueue', { key, options })
    return new Promise((resolve, reject): void => {
      let queueablePromise: QueueablePromise | null = null
      let resolveNow
      const abortPromise = new Promise((resolve): void => {
        resolveNow = (value): void => {
          devLog('forcibly resolving in-flight promise', { ...queueablePromise })
          resolve(value)
        }
      })

      const abortableMethod = async (): Promise<any> => {
        try {
          const result = await Promise.race([method(), abortPromise])
          resolve(result)
        } catch (e) {
          reject(e)
        }
      }

      queueablePromise = { key, method: abortableMethod, resolveNow }
      if (options.metadata) {
        queueablePromise.metadata = options.metadata
      }
      if (options.firstInLine) {
        this.queue.unshift(queueablePromise)
      } else {
        this.queue.push(queueablePromise)
      }
      if (!this.currentPromise) this.next()
    })
  }

  public unqueue(): QueueablePromise | null {
    return this.queue.pop() || null
  }

  // Inserts a task at the head of the queue to remove all subsequent promises matching given key.
  // We cannot immediately remove matched promises because the currentPromise might enqueue a new one
  // and we want to remove that new one if it matches.
  public async unqueueKey(key: string): Promise<QueueablePromise[]> {
    const unqueueKeyPromise = (): Promise<QueueablePromise[]> =>
      new Promise((resolve) => {
        const [unqueued, newQueue] = partition(this.queue, (queuedPromise) => queuedPromise.key === key)
        this.queue = newQueue
        resolve(unqueued)
      })
    return this.enqueue('unqueueKey', unqueueKeyPromise, { firstInLine: true })
  }

  public resolveCurrentPromiseNow(value: any): QueueablePromise | null {
    const currentPromise = this.currentPromise
    currentPromise?.resolveNow(value)
    return currentPromise
  }

  public clear(): QueueablePromise[] {
    const queuedItems = this.queue
    this.queue = []
    return queuedItems
  }

  // Does nothing if the queue is empty
  private async next(): Promise<any> {
    this.currentPromise = this.queue.shift() || null
    devLog('currentPromise started', { key: this.currentPromise?.key })
    if (!this.currentPromise) return
    try {
      await this.currentPromise.method()
      devLog('currentPromise done', { key: this.currentPromise?.key })
    } catch (e) {
      // We are using a queue in the first place because the items depend on one another.
      // Executing the subsequent items might give unexpected results, so skip them.
      this.clear()
    } finally {
      this.next()
    }
  }
}
